import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Post Counters',
  props: {},
  methods: {
    toggleComments: {
      description: 'Toggles the comments icon',
    },
  },
  events: {},
});
