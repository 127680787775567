import {
  ControllerStageDataManifest,
  GfppConfig,
} from '@wix/platform-editor-sdk';
import classicMobilePreset from '../assets/blocks-post-page/classic-mobile-preset.png';
import classicPreset from '../assets/blocks-post-page/classic-preset.png';
import overlayMobilePreset from '../assets/blocks-post-page/overlay-mobile-preset.png';
import overlayPreset from '../assets/blocks-post-page/overlay-preset.png';
import stackedMobilePreset from '../assets/blocks-post-page/stacked-mobile-preset.png';
import stackedPreset from '../assets/blocks-post-page/stacked-preset.png';
import postPageJson from '../components/Post Page/.component.json';
import { POST_PAGE_PRESETS } from '../components/Post Page/post-page-constants';
import { postPageWidgetDesign } from './blocks-post-page-manifest-widget-design';

const getPostPageWidgetDesktopPresets = (rtl: boolean) => [
  {
    id: rtl
      ? POST_PAGE_PRESETS.DESKTOP_CLASSIC_RIGHT
      : POST_PAGE_PRESETS.DESKTOP_CLASSIC_LEFT,
    src: classicPreset,
  },
  {
    id: rtl
      ? POST_PAGE_PRESETS.DESKTOP_OVERLAY_RIGHT
      : POST_PAGE_PRESETS.DESKTOP_OVERLAY_LEFT,
    src: overlayPreset,
  },
  {
    id: rtl
      ? POST_PAGE_PRESETS.DESKTOP_STACKED_RIGHT
      : POST_PAGE_PRESETS.DESKTOP_STACKED_LEFT,
    src: stackedPreset,
  },
];

const getPostPageWidgetMobilePresets = (rtl: boolean) => [
  {
    id: rtl
      ? POST_PAGE_PRESETS.MOBILE_CLASSIC_RIGHT
      : POST_PAGE_PRESETS.MOBILE_CLASSIC_LEFT,
    src: classicMobilePreset,
  },
  {
    id: rtl
      ? POST_PAGE_PRESETS.MOBILE_OVERLAY_RIGHT
      : POST_PAGE_PRESETS.MOBILE_OVERLAY_LEFT,
    src: overlayMobilePreset,
  },
  {
    id: rtl
      ? POST_PAGE_PRESETS.MOBILE_STACKED_RIGHT
      : POST_PAGE_PRESETS.MOBILE_STACKED_LEFT,
    src: stackedMobilePreset,
  },
];

export const overridePostPageWidgetManifest = ({
  controllersStageData,
  rtl,
  isCommentsEnabled,
}: {
  controllersStageData: ControllerStageDataManifest;
  rtl: boolean;
  isCommentsEnabled: boolean;
}) => {
  Object.assign(controllersStageData[postPageJson.controllerId].default, {
    behavior: {
      removable: false,
      duplicatable: false,
      toggleShowOnAllPagesEnabled: false,
      essential: {
        enabled: false,
      },
    },
  });

  const gfpp = controllersStageData[postPageJson.controllerId].default
    .gfpp as unknown as GfppConfig;

  if (gfpp?.desktop) {
    Object.assign(gfpp.desktop, {
      widgetDesign: postPageWidgetDesign,
      widgetPresets: { presets: getPostPageWidgetDesktopPresets(rtl) },
      iconButtons: {
        ...(gfpp.desktop as any).iconButtons,
        presets: { behavior: 'HIDE' },
        widgetPlugins: { behavior: isCommentsEnabled ? 'DEFAULT' : 'HIDE' },
      },
    });
  }

  if (gfpp?.mobile) {
    Object.assign(gfpp.mobile, {
      widgetPresets: { presets: getPostPageWidgetMobilePresets(rtl) },
      iconButtons: {
        ...(gfpp.mobile as any).iconButtons,
        presets: { behavior: 'HIDE' },
      },
    });
  }
};
