import postPageJson from '../components/Post Page/.component.json';
import { TPA_PAGE_ID_POST } from '../external/common/constants/tpa-pages';
import { EditorAppContext } from '../types/editor-app-context.type';

/**
 * **How to get preset ids:**
 *
 * 1. Add `&experiments=rightClickDebug` in Blocks editor.
 * 2. Right click on the widget and select "Debug > BoB App > Get Presets"
 **/
const blocksWidgets = {
  postPage: postPageJson,
} as const;

interface AddBlocksWidgetParams {
  context: EditorAppContext;
  widgetName: keyof typeof blocksWidgets;
}

export async function addBlocksWidget({
  context,
  widgetName,
}: AddBlocksWidgetParams) {
  const widget = blocksWidgets[widgetName];
  const rtl = context.flowAPI.environment.isSiteLanguageRTL;
  const pageRef = await context.sdk.tpa.getPageRefByTPAPageId(
    context.appToken,
    { tpaPageId: TPA_PAGE_ID_POST },
  );
  const preset = rtl ? widget.defaultRtlPresetId : widget.defaultPresetId;
  const mobilePreset = rtl
    ? widget.defaultRtlMobilePresetId
    : widget.defaultMobilePresetId;

  await context.sdk.pages.navigateTo(context.appToken, { pageRef });

  const componentRef = await context.sdk.application.appStudioWidgets.addWidget(
    context.appToken,
    {
      installationType: 'closed',
      containerRef: { id: pageRef.id, type: 'DESKTOP' },
      presetIds: {
        layout: preset,
        style: preset,
      },
      scopedPresets: {
        desktop: {
          layout: preset,
          style: preset,
        },
        mobile: {
          layout: mobilePreset,
          style: mobilePreset,
        },
      },
      widgetId: widget.id,
      layout: {
        x: 0,
        y: 0,
        height: 600,
        // @ts-expect-error
        docked: {
          left: {
            px: 0,
            vw: 0,
          },
          right: {
            px: 0,
            vw: 0,
          },
        },
      },
      layouts: {
        componentLayout: {
          type: 'ComponentLayout',
          hidden: false,
          height: { type: 'auto' },
          minHeight: { type: 'px', value: 600 },
        },
        itemLayout: {
          id: '',
          alignSelf: 'center',
          justifySelf: 'center',
          type: 'GridItemLayout',
          gridArea: { rowStart: 1, rowEnd: 2, columnStart: 1, columnEnd: 2 },
          margins: {
            top: { type: 'px', value: 0 },
            left: { type: 'px', value: 0 },
          },
        },
        containerLayout: {
          type: 'GridContainerLayout',
          columns: [{ type: 'fr', value: 1 }],
          rows: [{ type: 'fr', value: 1 }],
        },
      },
    },
  );

  return componentRef;
}
