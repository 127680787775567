export const POST_PAGE_SELECTORS = {
  POST_HEADER: 'postHeader1',
  POST_CONTENT: 'postContent1',
  POST_FOOTER: 'postFooter1',
  RELATED_POSTS: 'relatedPosts1',
  COMMENTS: 'comments1',
  POST_PAGE_MULTISTATE_BOX: 'postStates',
  AVAILABLE_STATE: 'availableState',
  EMPTY_STATE: 'emptyState',
  NOT_FOUND: '404State',
  SEE_MORE_POSTS_BUTTON: 'button1',
} as const;

export const POST_PAGE_PRESETS = {
  DESKTOP_CLASSIC_LEFT: 'variants-ln2sz6bn1',
  DESKTOP_CLASSIC_RIGHT: 'variants-lnisw8eu',
  MOBILE_CLASSIC_LEFT: 'variants-lnirkhtr',
  MOBILE_CLASSIC_RIGHT: 'variants-lnitwirp',
  DESKTOP_OVERLAY_LEFT: 'variants-lniv6gp0',
  DESKTOP_OVERLAY_RIGHT: 'variants-lniw3ojg',
  MOBILE_OVERLAY_LEFT: 'variants-lnivui2q',
  MOBILE_OVERLAY_RIGHT: 'variants-lniw3ojg',
  DESKTOP_STACKED_LEFT: 'variants-lniw545p',
  DESKTOP_STACKED_RIGHT: 'variants-lniwbvh8',
  MOBILE_STACKED_LEFT: 'variants-lniwdp8d',
  MOBILE_STACKED_RIGHT: 'variants-lniwhc6x',
} as const;
