import { EXPERIMENTS } from '@wix/communities-blog-client-common';
import relatedPostsJson from '../components/RelatedPosts/.component.json';
import {
  blogAppDefId,
  blogCommentsAppDefId,
  blogCommentsWidgetId,
} from '../external/common/constants/apps';
import { TPA_PAGE_ID_POST } from '../external/common/constants/tpa-pages';
import concurrentEditing from '../external/common/services/concurrent-editing';
import { EditorAppContext } from '../types/editor-app-context.type';
import { addBlocksWidget } from './add-blocks-widget';
import { addWidgetPlugin } from './add-widget-plugin';
import { updateBlogSettings } from './common/update-blog-settings';

const mapSlotWithWidgetId = {
  slotComments: {
    name: 'Comments',
    widgetId: blogCommentsWidgetId,
    appDefinitionId: blogCommentsAppDefId,
  },
  slotRelatedPosts: {
    name: 'Related Posts',
    widgetId: relatedPostsJson.id,
    appDefinitionId: blogAppDefId,
  },
} as const;

const successLog = (msg: string) =>
  console.log(msg, 'color: green; font-weight: bold;');

export const installPostPage = async (context: EditorAppContext) => {
  console.log('📝 [Blog] Installing Blocks Post Page...');

  await concurrentEditing.withApproval(async () => {
    try {
      const postPageRef = await addBlocksWidget({
        context,
        widgetName: 'postPage',
      });
      await removeOldPostWidget();

      console.info('📝 [Blog]', { postPageRef });
      console.info(
        '📝 [Blog] Installing Blocks Post Page... Adding plugins...',
      );

      const [widgetSlots] = await Promise.all([
        context.sdk.tpa.widgetPlugins.getWidgetSlots(context.appToken, {
          widgetRef: postPageRef,
        }),
        updateBlogSettings(context, { blocksPostPage: true }),
      ]);

      const isCommentsInstallationEnabled = context.flowAPI.experiments.enabled(
        EXPERIMENTS.ENABLE_COMMENTS_INSTALLATION,
      );

      for await (const slot of widgetSlots) {
        if (slot.role in mapSlotWithWidgetId) {
          const { widgetId, appDefinitionId, name } =
            mapSlotWithWidgetId[slot.role as keyof typeof mapSlotWithWidgetId];

          if (name === 'Comments' && !isCommentsInstallationEnabled) {
            return;
          }

          console.info(`📝 [Blog] Adding ${name} plugin...`);

          await addWidgetPlugin({
            widgetId,
            slotCompRef: slot.compRef,
            context,
            appDefinitionId,
          }).then(() => {
            successLog(`📝 [Blog] Adding ${name} plugin... %cDone!`);
          });
        }
      }

      successLog('%c📝 [Blog] Installing Blocks Post Page... Done!');
    } catch (err) {
      console.error('📝 [Blog] Installing Blocks Post Page... Failed!', err);
      throw err;
    }
  });

  async function removeOldPostWidget() {
    const pageRef = await context.sdk.tpa.getPageRefByTPAPageId(
      context.appToken,
      { tpaPageId: TPA_PAGE_ID_POST },
    );
    const children = await context.sdk.components.getChildren(
      context.appToken,
      { componentRef: pageRef },
    );

    if (children.length) {
      await context.sdk.components?.remove(context.appToken, {
        componentRef: children[0],
      });
    }
  }
};
