export const POST_FOOTER_SELECTORS = {
  CATEGORIES: 'categories1',
  TAGS: 'tags1',
  METADATA: 'metadata1',
  WRITER: 'text1',
  PUBLISH_DATE: 'text5',
  READING_TIME: 'text2',
  LAST_UPDATED: 'text3',
  LIKES: 'statesContainer',
  VIEWS: 'viewsContainer',
  COMMENTS: 'commentsContainer',
  DIVIDER: 'dividerRoot',
  COUNTERS: 'postCountersNew1',
};
